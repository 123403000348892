import {SafeResourceUrl} from '@angular/platform-browser';

export class UserModel {
    public appVersion: string = '';
    public accountNumber: string = '';
    public cardNumber: string = '';
    public bepozId: string = '';
    public dob: Date = new Date();
    public emailAddress: string | undefined;
    public fcmToken: string = '';
    public firstName: string | undefined;
    public hasSeenIntro: boolean = true;
    public id: string = '';
    public interests: string[] | undefined;
    public lastName: string | undefined;
    public phoneNumber: string | undefined;
    public photoPath: string | SafeResourceUrl = '';
    public referredBy: string = '';
    public rewardsPoints: number = 0;
    public tier: number = 0;

    constructor(model?: Partial<any>) {
        if (model) {
            Object.assign(this, model);
        }
    }
}

export class AuthUserModel extends UserModel {
    public token: string | undefined;
}
