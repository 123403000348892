export const environment = {
    production: false,
    env: 'dev',
    BASE_API_URL: 'https://admin.dev.artesianloyalty.com/api',
    mapsApiKey: 'AIzaSyCOFfZN8YzG6o7yQiVDn9dm_buoNZZ2_OI',
    GO_API_URL: 'https://dev.artesianloyalty.com',
    firebaseConfig: {
        apiKey: "AIzaSyABEVR5oFX6QpjwL_dGEQE4kSlxcCLlh4Y",
        authDomain: "artesian-loyalty.firebaseapp.com",
        projectId: "artesian-loyalty",
        storageBucket: "artesian-loyalty.appspot.com",
        messagingSenderId: "442988404877",
        appId: "1:442988404877:web:841e0a14a3c6508d8a42c4",
        measurementId: "G-BT88NSH832"
    }
};
