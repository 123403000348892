import {inject, Injectable, OnDestroy} from '@angular/core';
import {Auth, authState, signInWithEmailAndPassword, signOut, User, UserCredential} from '@angular/fire/auth';
import {Router} from '@angular/router';

import {from, Observable, of, Subscription, switchMap, tap} from 'rxjs';

import {environment} from '../../../../environments/environment';
import {LoginInterface} from '../../../pages/login/common/login.interfaces';
import {collectionNames} from '../../constants/constants';
import {routeType} from '../../constants/routes.constants';
import {AuthUserModel} from '../../models/user.model';
import {FirebaseService} from '../firebase/firebase.service';
import {LocalStorageService} from '../local-storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    private auth: Auth = inject(Auth);
    authState$ = authState(this.auth);
    authStateSubscription: Subscription;

    constructor(
        private firebaseService: FirebaseService,
        private localStorage: LocalStorageService,
        private router: Router
    ) {
        this.authStateSubscription = this.authState$.subscribe((aUser: User | null) => {
            if (aUser) {
                let authUser: AuthUserModel;

                from(this.firebaseService.getSpecificDocument(collectionNames.users, aUser.uid)).pipe(
                    switchMap((user) => {
                        if (user) {
                            authUser.firstName = user.firstName;
                            authUser.lastName = user.lastName;
                            authUser.emailAddress = user.emailAddress;
                            authUser.id = user.id;
                        }

                        return from(aUser.getIdTokenResult());
                    }),
                    tap((idToken) => {{
                        if (environment.production) {
                            // Check if the user is an admin to login in the app
                            if (idToken.claims['admin']) {
                                authUser.token = idToken.token;
                            } else {
                                return;
                            }
                        } else {
                            authUser.token = idToken.token;
                        }

                        this.setUserDetails(authUser);
                    }})
                );
            } else {
                this.localStorage.clearUserDetails();
                this.router.navigateByUrl(routeType.Login.route);
            }
        });
    }

    public ngOnDestroy(): void {
        this.authStateSubscription.unsubscribe();
    }

    public isAuthValid(): Observable<boolean> {
        const currentUser = this.getAuthUser();
        if (currentUser && currentUser.token) {
            return of(true);
        } else {
            return of(false);
        }
    }

    public isAuthInvalid(): Observable<boolean> {
        const currentUser = this.getAuthUser();
        if (!currentUser || !currentUser.token) {
            return of(true);
        } else {
            return of(false);
        }
    }

    /**
     * Login with email and password using the firebase authentication
     * @param loginData loginFormModel: email and password
     */
    public loginFirebase(loginData: LoginInterface): Observable<any> {
        let userData: User;
        let authUser: AuthUserModel;

        return from(signInWithEmailAndPassword(this.auth, loginData.email, loginData.password)).pipe(
            switchMap((userCredential: UserCredential) => {
                userData = userCredential.user;

                return this.firebaseService.getSpecificDocument(collectionNames.users, userData.uid);
            }),
            switchMap((user) => {
                authUser = new AuthUserModel();
                if (user) {
                    authUser.firstName = user.firstName;
                    authUser.lastName = user.lastName;
                    authUser.emailAddress = user.emailAddress;
                    authUser.id = user.id;
                } else {
                    authUser.emailAddress = userData.email || undefined;
                    authUser.id = userData.uid;
                }

                return from(userData.getIdTokenResult());
            }),
            tap((idToken) => {
                if (environment.production) {
                    // Check if the user is an admin to login in the app
                    if (idToken.claims['admin']) {
                        authUser.token = idToken.token;
                    } else {
                        return;
                    }
                } else {
                    authUser.token = idToken.token;
                }
                this.setUserDetails(authUser);

                return authUser;
            }),
        );
    }

    /**
     * Logging out the user from firebase authentication
     * Clearing the localStorage user information and redirecting to the login page
     */
    public logoutFirebase(): void {
        signOut(this.auth)
        .then(() => {
            this.localStorage.clearUserDetails();
            this.router.navigateByUrl(routeType.Login.route);
        })
        .catch(() => {
            return false;
        });
    }

    /**
     * Retrieve the user information from the local storage
     * @returns it returns a converted user model if exists
     */
    public getAuthUser(): AuthUserModel | undefined {
        return this.localStorage.getUserDetails();
    }

    /**
     * Save the user details to the local storage to manage the access details along the application
     * @param user user model details
     */
    private setUserDetails(user: AuthUserModel): void {
        this.localStorage.setUserDetails(user);
    }
}
