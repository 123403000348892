export class Constants {
    public static VALIDATION_ERROR_REQUIRED = (a: string) => {
        return `Please complete '${a}'. `;
    }
    public static VALIDATION_ERROR_MAXLENGTH = (a: string, b: string) => {
        return `'${a}' must be less than ${b} characters. `;
    }
    public static VALIDATION_ERROR_MINLENGTH = (a: string, b: string) => {
        return `'${a}' must have at least ${b} characters. `;
    }
    public static VALIDATION_ERROR_MINLENGTHARRAY = (a: string, b: string) => {
        return `'${a}' must have at least ${b} entry. `;
    }
    public static VALIDATION_ERROR_MAX = (a: string, b: string) => {
        return `'${a}' must be less than ${b}. `;
    }
    public static VALIDATION_ERROR_MIN = (a: string, b: string) => {
        // If number is not whole integer use greater than or equal to text otherwise assume we're using whole numbers
        if (typeof b === 'number' && !Number.isInteger(b)) {
            return `'${a}' must be greater than or equal to ${b}. `;
        } else {
            return `'${a}' must be greater than ${Number(b) - 1}. `;
        }
    }
    public static VALIDATION_ERROR_EMAIL = (a: string) => {
        return `Please enter a valid '${a}'. `;
    }
    public static VALIDATION_ERROR_ARRAYUNIQUE = (a: string) => {
        return `'${a}' must be unique. `;
    }
    public static VALIDATION_ERROR_WHITESPACE = (a: string) => {
        return `'${a}' cannot be blank. `;
    }
    public static sourceMapApi = (apiKey: string) => {
        return 'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&region=AU&language=en';
    }
}

export const channels = {
    all: {key: 'all', name: 'All users'},
    sinCity: {key: 'sin_city', name: 'Sin city'},
    havana: {key: 'havana', name: 'Havana'},
    bedroom: {key: 'bedroom', name: 'Bedroom'},
    whiteRhino: {key: 'white_rhino', name: 'White rhino'},
    surfersPavilion: {key: 'surfers_pav', name: 'Surfers pavilion'},
    caliBeach: {key: 'cali_beach', name: 'Cali beach'},
    restaurants: {key: 'restaurants', name: 'Restaurants'},
    nightclubs: {key: 'nightclubs', name: 'Nightclubs'},
};

export const collectionNames = {
    notifications: 'notifications',
    geofences: 'geofences',
    users: 'users',
    vouchers: 'vouchers',
};

export const localStorageKeys = {
    userDetails: 'userDetails',
};

export const targets = {
    channels: {key: 'channels', name: 'Channels'},
    users: {key: 'users', name: 'Users'},
};

export const filterByUsers = {
    name: {key: 'name', name: 'Name'},
    email: {key: 'email', name: 'Email'},
    mobile: {key: 'mobile', name: 'Mobile number'},
    bepos: {key: 'beposId', name: 'Bepos account Id'},
};

export const paginationOptions = [5, 10, 25, 50];

export const days = {
    monday: {key: 0, name: 'Monday'},
    tuesday: {key: 1, name: 'Tuesday'},
    wednesday: {key: 2, name: 'Wednesday'},
    thursday: {key: 3, name: 'Thursday'},
    friday: {key: 4, name: 'Friday'},
    saturday: {key: 5, name: 'Saturday'},
    sunday: {key: 6, name: 'Sunday'},
};

export const actionType = {
    voucher: {key: 0, name: 'Voucher'},
    notification: {key: 1, name: 'Notification'},
};

export const voucherType = {
    dollarValue: {key: 0, name: 'Dollar value'},
    limitedUses: {key: 1, name: 'Limited uses'},
    percentageDiscount: {key: 2, name: 'Percentage discount'},
};

export const interestsMailChimp = {
    sinCity: {key: '0acf26a316', name: 'SinCity'},
    bedroom: {key: '37204a8471', name: 'Bedroom'},
    havana: {key: '410f034669', name: 'Havana'},
    whiteRhino: {key: 'bfcd837b52', name: 'White Rhino'},
    caliBeach: {key: '6496bb5d34', name: 'Cali Beach'},
    surfersPavilion: {key: '359e65db62', name: 'Surfers Pavilion'},
};
