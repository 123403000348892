import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {routeType} from '../constants/routes.constants';
import {AuthService} from '../services/auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivateChild {

    constructor(private authService: AuthService, private router: Router) {
    }

    public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Observable<UrlTree> | Observable<boolean | UrlTree> {
        return this.authService.isAuthValid().pipe(
            map((isValid: boolean) => {
                if(!isValid) {
                    return this.router.parseUrl(`/${routeType.Login.route}`);
                }

                return isValid;
            }),
        );
    }
}
@Injectable()
export class NotAuthedGuard implements CanActivateChild {

    constructor(private router: Router, private authService: AuthService) {
    }

    public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Observable<UrlTree> | Observable<boolean | UrlTree> {
        return this.authService.isAuthInvalid().pipe(
            map((isInvalid: boolean) => {
                if(!isInvalid) {
                    return this.router.parseUrl('/');
                }

                return isInvalid;
            }),
        );
    }
}
