import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})

export class ConfirmDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, body: string},
                public dialogRef: MatDialogRef<ConfirmDialogComponent>) {

    }

    confirm() {
        this.dialogRef.close(true)
    }

    cancel() {
        this.dialogRef.close(false);
    }
}
