// Framework imports
import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {routeType} from './common/constants/routes.constants';
import {AuthGuard, NotAuthedGuard} from './common/navigation-guards/auth-guard';

const router: Routes = [
    {
        path: routeType.Login.route,
        canActivateChild: [NotAuthedGuard],
        loadChildren: async () => (await import('./pages/login/common/login.module')).LoginModule,
    },
    {
        path: routeType.PageNotFound.route,
        loadChildren: async () => (await import('./pages/not-found/common/not-found.module')).NotFoundModule,
    },
    {
        path: '',
        canActivateChild: [AuthGuard],
        loadChildren: async () => (await import('./app-content/common/app-content.module')).AppContentModule,
    },
    {
        path: '**',
        redirectTo: routeType.PageNotFound.route,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(router)],
    exports: [RouterModule],
    providers: [
        AuthGuard,
        NotAuthedGuard,
    ],
})
export class AppRoutingModule { }
