// Framework imports
import { Injectable } from '@angular/core';

// project specific imports
import {localStorageKeys} from '../../constants/constants';
import {AuthUserModel} from '../../models/user.model';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {

    private localStorageKeys: typeof localStorageKeys = localStorageKeys;

    constructor() { }

    /**
     * Set user details of a user once it is login through firebase
     * @param user user information to be stored
     */
    public setUserDetails(user: AuthUserModel): void {
        this.clearLocalStorage();
        localStorage.setItem(this.localStorageKeys.userDetails, JSON.stringify(user));
    }

    /**
     * retrieve the user information stored in the localstorage
     * @returns the user stored in the localstorage if exists
     */
    public getUserDetails(): AuthUserModel | undefined {
        const user = localStorage.getItem(this.localStorageKeys.userDetails);
        if (user) {

            return new AuthUserModel(JSON.parse(user));
        }

        return;
    }

    /**
     * Clear the user details once the user is logout using the firebase authentication
     */
    public clearUserDetails(): void {
        localStorage.removeItem(this.localStorageKeys.userDetails);
    }

    /**
     * Clear all the keys stored in the local storage
     */
    public clearLocalStorage(): void {
        localStorage.clear();
    }
}
