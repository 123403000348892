export enum TextConstants {
    accessDetails = 'please log into your account',
    accountId = 'Account Id',
    action = 'action',
    actions = 'actions',
    active = 'active',
    addAction = 'Add action',
    addDescription = 'Add a description',
    addGeofence = 'add geofence',
    addName = 'Add a descriptive name',
    addNotification = 'add notification',
    addNewGeofence = 'add new geofence',
    addNewNotification = 'add new notification',
    addNewUser = 'add new user',
    adminTitle = 'Admin Dashboard',
    amountIssued = 'Amount issued',
    amountLeft = 'Amount left',
    appVersion = 'App version',
    back = 'back',
    begin = 'begin',
    bepozAccountNumber = 'Bepoz account number',
    bepozCardNumber = 'Bepoz card number',
    bepozId = 'Bepoz ID',
    body = 'body',
    conditions = 'Conditions',
    confirmRemoveItem = 'are you sure you want to remove this item?',
    coolDown = 'Cool down time from 0 to 24 hours',
    channels = 'channels',
    create = 'create',
    dateOfBirth = 'Date of birth',
    daysWeekTitle = 'Days of the week',
    deleteAction = 'delete action',
    deleteGeofence = 'delete geofence',
    description = 'description',
    duration = 'duration in hours from 0 to 24 hours',
    edit = 'edit',
    editAction = 'edit action',
    editGeofence = 'Edit geofence',
    email = 'Email',
    errorRangeRadius = 'Radius value must be between 200 and 20000',
    expiryDate = 'Expiry date',
    filterBy = 'Filter By',
    filterValue = 'Filter Value',
    firstName = 'First name',
    geofence = 'geofence',
    geofences = 'geofences',
    hasSeenIntro = 'Has seen intro?',
    interests = 'Interests',
    invalidLogin = 'Invalid login',
    isActive = 'is active',
    isTemplate = 'is template',
    issuedDate = 'Issued date',
    lastUpdatedDate = 'Last updated date',
    lastName = 'Last name',
    latitude = 'latitude',
    login = 'login',
    logout = 'log out',
    longitude = 'longitude',
    mobileNumber = 'Mobile Number',
    name = 'Name',
    noData = 'no data',
    notFoundErrorCode= '404',
    notification = 'notification',
    notificationBody = 'Notification body',
    notifications = 'notifications',
    notificationTitle = 'Notification title',
    optional = 'optional',
    pageNotFound = 'page not found',
    password = 'password',
    radius = 'radius',
    referredBy = 'Referred by',
    remove = 'Remove',
    rewardPoints = 'reward points',
    requiredField = 'Required field',
    save = 'save',
    select = 'Select',
    send = 'send',
    setupId = 'Setup Id',
    somethingWentWrong = 'oops! something went wrong',
    status = 'status',
    target = 'target',
    template = 'template',
    beginDurationTitle = 'Begin and duration',
    timeFormat = '04:00 PM',
    tier = 'Tier',
    title = 'title',
    type = 'type',
    update = 'updates',
    userId = 'User ID',
    userMustBeAdmin = 'User must be an admin',
    userNotFound = 'User not found',
    users = 'users',
    viewUserDetails = 'View user details',
    voucherId = 'Voucher ID',
    ViewVoucher = 'View voucher',
    voucherType = 'Voucher Type',
    vouchers = 'Vouchers',
}

export const FirebaseStatusMessages = {
    errorDeleting: (itemName: string) => `Error deleting ${itemName}`,
    errorUpdating: (itemName: string) => `Error updating ${itemName}`,
    errorRetrieving: (itemName: string) => `Error retrieving ${itemName}`,
    errorSaving: (itemName: string) => `Error saving ${itemName}`,
    successfulCreation: (itemName: string) => `Successfully added ${itemName}`,
    successfulDeletion: (itemName: string) => `Successfully deleted ${itemName}`,
    successfulEdition: (itemName: string) => `Successfully edited ${itemName}`,
};
