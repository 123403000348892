// Framework imports
import {HttpClientModule} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {FIREBASE_OPTIONS} from '@angular/fire/compat';
import { getFirestore,provideFirestore } from '@angular/fire/firestore';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { DecimalPipe } from '@angular/common';

// project specific imports
import {environment} from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {SnackBarService} from './common/services/snack-bar/snack-bar.service';
import { ConfirmDialogComponent } from './app-content/pages/wof/components/confirm-dialog/confirm-dialog.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
    declarations: [
        AppComponent,
        ConfirmDialogComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        HttpClientModule,
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),
        MatButtonModule,
        MatDialogModule,
    ],
    providers: [
        { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
        SnackBarService,
        DecimalPipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    public constructor() { }
}
