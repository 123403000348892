<div class="dialog-window">
    <h2 mat-dialog-title>{{data.title}}</h2>
    <div mat-dialog-content>
        <p>{{data.body}}</p>
        <p>Do you wish to continue?</p>
        <div class="button-row d-flex flex-row justify-content-end">
            <button mat-flat-button (click)="cancel()" class="cancel-btn">Cancel</button>
            <button mat-raised-button (click)="confirm()" class="confirm-btn">Confirm</button>
        </div>
    </div>
</div>