// Framework imports
import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

const durationNumber = 3000;
const verticalPos: MatSnackBarVerticalPosition = 'top';
const failureConfig: MatSnackBarConfig = {
    duration: durationNumber,
    panelClass: ['bg-danger'],
    verticalPosition: verticalPos,
};
const successConfig: MatSnackBarConfig = {
    duration: durationNumber,
    panelClass: ['bg-success'],
    verticalPosition: verticalPos,
};
const warningConfig: MatSnackBarConfig = {
    duration: durationNumber,
    panelClass: ['bg-warning'],
    verticalPosition: verticalPos,
};
const informationConfig: MatSnackBarConfig = {
    duration: durationNumber,
    panelClass: ['bg-info'],
    verticalPosition: verticalPos,
};

/**
 * Snack-bar with a custom component
 */
@Injectable({
    providedIn: 'root',
})
export class SnackBarService {
    constructor(public snackBar: MatSnackBar) {
    }

    public snackError(message: string): void {
        this.snackBar.open(message, '', failureConfig);
    }

    public snackSuccess(message: string): void {
        this.snackBar.open(message, '', successConfig);
    }

    public snackWarning(message: string): void {
        this.snackBar.open(message, '', warningConfig);
    }

    public snackInformation(message: string): void {
        this.snackBar.open(message, '', informationConfig);

    }
}
