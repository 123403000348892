import { Injectable } from '@angular/core';
import {Action, AngularFirestore, CollectionReference, DocumentChangeAction, Query, QuerySnapshot, DocumentData} from '@angular/fire/compat/firestore';

import {from, map, Observable} from 'rxjs';

import {TextConstants} from '../../constants/text.constants';
import {FirebaseQuery} from '../../interfaces/firebase-query.interface';

@Injectable({
    providedIn: 'root',
})
export class FirebaseService {

    public textConstants: typeof TextConstants = TextConstants;

    constructor(private angularFirestore: AngularFirestore) { }

    /**
     * Generic function to retrieve a specific document of a collection
     * @param collectionName: collection to retrieve the documents for
     * @param firebaseQueries An array of firebase 'where' constraints to query specific data
     * See further details how to structure said constraints here - https://firebase.google.com/docs/firestore/query-data/queries
     */
    public getAllDocuments(collectionName: string, firebaseQueries: FirebaseQuery[] = []): Observable<any[]> {
        return this.angularFirestore.collection(
            collectionName,
            (ref) => {
                let query : CollectionReference | Query = ref;
                // Iterate of firebase query array and apply all queries with a .where()
                for (const firebaseQuery of firebaseQueries) {
                    query = query.where(firebaseQuery.fieldPath, firebaseQuery.opStr, firebaseQuery.value);
                }

                return query;
            },
        ).snapshotChanges().pipe(
            map((changes: DocumentChangeAction<any>[]) => {
                return changes.map((change) => {
                    return change.payload.doc.data();
                    // We can add the document id here if we stop manually adding it on the create function
                    // return ({id: change.payload.doc.id, ...change.payload.doc.data()});
                });
            }),
        );
    }

    /**
     * Generic function to return a specific document from a specific collection
     * The errors are handled individually by the children in order to create customisable alerts
     * @param collectionName: collection to retrieve the document for
     * @param id: the identifier document in the DB
     */
    public getSpecificDocument(collectionName: string, id: string): Observable<any> {
        return this.angularFirestore.collection(collectionName).doc(id)
        .snapshotChanges().pipe(
            map((changes: Action<any>) => {
                return changes.payload;
            }),
        );
    }

    /**
     * Generic function to set data to a specific collection
     * The errors are handled individually by the children in order to create customisable alerts
     * @param collectionName: collection to set the document for
     * @param createObject: object to create in the database
     */
    public createDocument(collectionName: string, createObject: Partial<any>): Observable<any> {
        if (!createObject['id']) {
            createObject['id'] = this.angularFirestore.createId();
        }

        return from(this.angularFirestore.collection(collectionName).doc(createObject['id']).set({...createObject, createdAt: new Date()}));
    }

    /**
     * Edit a document in the database with the given id
     * The errors are handled individually by the children in order to create customisable alerts
     * @param collectionName collection to set the document for
     * @param id Document id to update
     * @param updateObject Fields to update
     */
    public editDocument(collectionName: string, id: string, updateObject: Partial<any>): Observable<any> {
        return from(this.angularFirestore.collection(collectionName).doc(id).update(updateObject));
    }

    getData(): Observable<any> {
        return this.angularFirestore.collection('users').valueChanges();
    }

    getTierData(): Observable<any> {
        return this.angularFirestore.collection('users', ref => ref.limit(10)).valueChanges();
    }
        
    getTeamData(): Observable<any[]> {
        return this.angularFirestore.collection<any>('users', ref => ref.where('appPermissions', '==', 3)).valueChanges();
    }
}
